import React from "react";
import { Container } from "@atoms";
import loadable from "@loadable/component";
import { CopySection } from "@molecules";

const VennDiagram = loadable(() => import("@organisms/VennDiagram"));

const Approach = ({ heading, copy, approaches }) => {
  return (
    <Container>
      <div className="relative h-[300vh] text-white">
        <CopySection
          className="-mb-[33vh] text-white"
          heading={heading}
          copy={copy}
        />
        <VennDiagram heading={heading} copy={copy} circles={approaches} />
      </div>
    </Container>
  );
};

export default Approach;
